import Head from 'next/head';
import React from 'react';
import { useRouter } from 'next/router';
import { generateOnlineStore } from '@/utils/schema';
// Json format to set seo meta data
// const hocHeadData = {
//   meta: {
//     title: null,
//     robots: null,
//     keyword: null,
//     description: null,
//     image: null,
//   },
//   jsonLdData: {
//     faqSchema: null,
//     listItemSchema: null,
//     breadcrumbSchema: null,
//   },
// };

interface Props {
  children?: React.ReactNode;
  seo?: any;
}

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

const HocHead: React.FC<Props> = ({ children, seo = {} }) => {
  const router = useRouter();
  const canonicalURL = `${baseUrl}${router.asPath}`;
  const jsonLdData = generateOnlineStore();

  const {
    image = 'https://www.woodenstreet.com/images/cover.gif',
    keyword = 'furniture online, wooden furniture, furniture India, solid wood furniture, buy furniture in India, buy wooden furniture online, customized furniture in India, shop furniture in India, online furniture for home',
    description = 'Furniture Online: Buy Wooden Furniture Online for Home & Office. Browse exclusive range of Living, Dining & Bedroom Furniture online at best prices made of solid wood. Buy from popular Wooden Furniture Store!',
    title = 'Woodenstreet',
    // robots,
  } = seo.meta || {};

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta name="Language" content="English" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#435471" />
      <meta name="author" content="https://www.woodenstreet.com" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="SKYPE_TOOLBAR" content="SKYPE_TOOLBAR_PARSER_COMPATIBLE" />
      <meta http-equiv="x-dns-prefetch-control" content="on" />

      <meta property="og:type" content="website" />
      <meta property="og:site" content="https://www.woodenstreet.com" />
      <meta property="og:url" content={canonicalURL} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta
        name="google-signin-client_id"
        content="317290152098-bmds3vnmcdhc2hbab6t2peebbanmq5rt.apps.googleusercontent.com"
      />
      <meta
        name="facebook-domain-verification"
        content="7ub26r4phmrftsumvuj9p039nbbzu7"
      />
      <meta name="p:domain_verify" content="9ee26ce0d5b05b03519b5257bb22142b" />

      <link rel="canonical" href={canonicalURL} />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/images/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/images/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/images/favicon-16x16.png"
      />

      <title>{title}</title>
      <meta name="keywords" content={keyword} />
      <meta name="description" content={description} />
      <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
      {/* {robots && <meta name="ROBOTS" content={robots} />} */}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: jsonLdData,
        }}
      />

      {seo?.jsonLdData &&
        Object.keys(seo.jsonLdData).map((key) => {
          const schema = seo.jsonLdData[key];
          return (
            schema && (
              <script
                key={key}
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: schema,
                }}
              />
            )
          );
        })}
      {/* <meta property="product:category" content="Furniture" /> */}
      {children ? children : <></>}
    </Head>
  );
};

export default HocHead;
